.stats-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    padding-bottom: 20px;
}

.stats-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    border: 2px solid #007bff;
    border-radius: 12px;
    color: #007bff;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: transparent;
    width: 180px;
    height: 50px;
    flex: 0 0 auto;
    white-space: nowrap;
}

.stats-box span {
    margin-right: 8px;
    font-weight: normal;
}

.stats-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1.1rem;
    color: #007bff;
    margin-right: 10px;
}